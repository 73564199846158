.swiper-container .swiper {
  padding-bottom: 10px;
  overflow: visible;
}
.swiper-container .swiper .swiper-slide {
  height: auto !important;
}

.reviews-swiper .swiper {
  overflow: visible;
}

.swiper-pagination {
  position: relative;
  padding-top: 10px;
}
.swiper-pagination .swiper-pagination-bullet-active {
  background-color: #006F95;
}